import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/Layout';
import EventsList from '../../components/EventsList';
import Helmet from 'react-helmet';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import strings from './strings.json';

const Events = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  const { location } = props;
  const {title, description} = strings;

  return (
    <Layout>
      <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        description={description}
        openGraph={{
          url: location.href,
          title: `${title} — ${storeName}`,
          description: description,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false} />
      <EventsList {...props} title={title} description={description} />
    </Layout>
  );
}

export default Events;

export const query = graphql`
  query {
    events: allEvent {
      nodes {
        content {
          description
          image {
            id
            name
            childImageSharp {
              thumb: gatsbyImageData(
                width: 450
                height: 450
                placeholder: BLURRED
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        what
        when
        where
        id
      }
    }
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`
