import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Breadcrumbs from '../Breadcrumbs';

import Gallery from '@browniebroke/gatsby-image-gallery'


function EventsList(props) {
  const { title, description } = props;
  const eventImages = props.data.events.nodes.flatMap((event) => {
    var title = `${event.what} - ${event.where} - ${event.when}`;
    var images = event.content.map((node) => {
      var image = {
        id: node.image.id,
        thumb: node.image.childImageSharp.thumb,
        full: node.image.childImageSharp.full,
        title: title,
        thumbAlt: node.description ? node.description : title,
      };
      if (node.description) image.caption = node.description;

      return image;
    });
    return images;
  });

  return (
    <Flex flexWrap="wrap" px={2} pt={3} mx="auto">
      <Flex
        width={1}
        px={4}
        py={2}
        flexWrap='wrap'
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box
          sx={{
            display: 'block',
          }}
          pt={1}
        >
          <Breadcrumbs
            productTitle={title}
            separator="/"
          />
        </Box>
        <Box width={1} mb={2}>
          <Heading as="h1" fontSize={[30, 36, 42]} my={3}>
            {title}
          </Heading>
          <Text fontSize={[2, 3]}>{description}</Text>
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        px={4}
        py={2}
      >
        <Gallery images={eventImages} />
      </Flex>
    </Flex>

  );
}

export default EventsList;
